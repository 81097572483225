
import React from 'react'
import Form from 'react-bootstrap/Form'

export default class ConstrainedFormGroup extends React.Component { 

	static defaultProps = {
		disabled: false
	}

	handleInput(value) { 
		var re = null
		if (this.props.regex !== null) { 
			re = this.props.regex
		}
		
		if (this.props.type === 'alphanumeric') { 
			re = /^[a-zA-Z0-9_.-]+$/
		}

		if (this.props.type === 'numeric') { 
			re = /^[0-9.]+$/
		}

		if (this.props.type === "integer")  { 
			re = /^[0-9]+$/
		}

		if (re.exec(value) === null && value !== '') { 
			return
		} 
		this.props.onInput(value)
	}

	getFormName()  { 
		if (this.props.formName !== undefined) { 
			return this.props.formName
		} else { 
			return null
		}
	}

	render() { 
		return (
			<Form.Group>
					<Form.Label>{this.props.label}</Form.Label>
					<Form.Control value={this.props.value} 
							name={this.getFormName()}
							onChange={(e) => this.handleInput(e.target.value)} 
							disabled={this.props.disabled}/>
			</Form.Group>
			)
	}

}