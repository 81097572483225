import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginButton, GoogleLoginButton, AppleLoginButton } from "react-social-login-buttons";
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { consoleLog } from '../managers/Logger'
import * as Sentry from '@sentry/browser'
import { connect } from 'react-redux'

export type SocialSigninType = "facebook" | "google" | "apple" | "manual"

export type redirectDest = {
    destination?: string
}

export type SocialSigninData = { 
    name?: string, 
    picture_url?: string, 
    email?: string, 
    token?: string,
    code?: string, 
    accountType?: string
}

export type SocialContainerViewProps = { 
    includeManual: boolean,
    handleSignin: (type: SocialSigninType, data: SocialSigninData, dest: redirectDest) => void, 
    handleError: (type: SocialSigninType, error: Error) => void
    handleFailure: (type: SocialSigninType, message: string) => void,
    appDomain?: string,
    destination?: string,
    showAppleLogin?: boolean
}

type State = { 
    googleLoginClicked: boolean,
    googleLoginInitError: any,
    checkAppleRedirect: boolean
}

export default class SocialContainerView extends React.Component<SocialContainerViewProps, State> { 

    constructor(props) { 
        super(props);
        this.state = { 
            googleLoginClicked: false, 
            googleLoginInitError: {
                error: ""
            },
            checkAppleRedirect: false
        }
    }

    async componentDidMount() {
        if (window.location.href.includes("state")) {
            this.handleSignUpRedirectedUrls(window.location.hash.substr(1))
        } else {
            this.setState({checkAppleRedirect: true})
        }
    }

    handleSignUpRedirectedUrls(redirectHash) {
        var paramsArray = this.hashArrayProcessing(redirectHash)
        var socialLoginType = paramsArray.state.split(':')[1]
        var finalDestination = paramsArray.state.split(':')[0]
        switch(socialLoginType){
            case "apple_login": {
                var idToken = paramsArray.id_token
                var authCode = paramsArray.code
                this.props.handleSignin("apple", {code: authCode, token: idToken, accountType: "apple"}, {destination: finalDestination})
            }
        }
    }

    hashArrayProcessing(hash) {
        var result = hash.split('&').reduce(function (res, item) {
            var parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});
        return result;
    }

    handleGoogleFailureCallback(err) { 
        consoleLog(err)
        Sentry.captureException(err)
        if (this.state.googleLoginClicked === true)  {
            var errorText = "Sorry, we failed to get your information from Google. Please try again"
            var browserErrorText = "Your browser doesn't support login with Google. Please try again with an updated version of Chrome, Firefox or Safari."

            if (err.error == "idpiframe_initialization_failed") { 
                errorText = browserErrorText
            }
            var googleInitError = this.state.googleLoginInitError
            if (googleInitError && googleInitError.error == "idpiframe_initialization_failed") { 
                errorText = browserErrorText
            }
            this.props.handleFailure("google", errorText);
        } else { 
            this.setState({googleLoginInitError: err})
        }
    }

    handleGoogleLoginCallback(data) {
        consoleLog(`[Successful Google login]: ${data}`);
        consoleLog(data);
        try { 
            var profileData = data.profileObj;
            var name = profileData.givenName + " " +  profileData.familyName;
            this.props.handleSignin("google", {name: name, email: profileData.email, picture_url: profileData.imageUrl, token: data.tokenObj.id_token, accountType: data.tokenObj.idpId}, {destination: null})
        } catch (error: any) { 
            consoleLog(`Error while handling google callback.`);
            this.props.handleError("google", error);
            Sentry.captureException(error);
        }
    }

    handleContinueWithoutSocialLogin = () => {
        this.props.handleSignin("manual", {name: "", email: "", picture_url: "", token: "", accountType: ""}, {destination: null})
    }

    handleFBLoginCallback(data) {
        consoleLog(data)
        try { 
            if (data) {
                var pictureData = data.picture
                var image = ""
                if (pictureData) { 
                    image = pictureData.data.url
                } 
                this.props.handleSignin("facebook", {name: data.name, picture_url: image, email: data.email, token: data.accessToken, accountType: "facebook"}, {destination: null});
            } else { 
                this.props.handleFailure("facebook", "Sorry, we failed to get your information from FB. Please try again");
            }
        } catch (err: any) { 
            Sentry.captureException(err)
            this.props.handleError("facebook", err)
        }
    }

    getFbKey() { 
        var fbKey = process.env.fbAppId;
        if (this.props.appDomain !== "witheta") { 
            fbKey = process.env.spacesFbId;
        }
        return fbKey;
    }

    getStateString(loginType) {
        var stateString = ''

        if (this.props.destination){
            stateString += this.props.destination
        }

        if (loginType == "apple") {
            stateString += ":apple_login"
        }

        return stateString
    }

    render() { 
        return <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
           <GoogleLogin
                   clientId={process.env.googleOauthKey}
                   render={renderProps => (
                       <GoogleLoginButton
                           onClick={() => { 
                               this.setState({googleLoginClicked: true})
                               renderProps.onClick()
                           }}
                           text={"Continue with Google"}
                           style={{}}
                       />
                   )}
                   onSuccess={(data) => this.handleGoogleLoginCallback(data)}
                   onFailure={(e) => this.handleGoogleFailureCallback(e)}
                   cookiePolicy={'single_host_origin'}
               />
       </div>

       {this.state.checkAppleRedirect && <div id="social_media_lgin_view" style={{ display: 'flex', justifyContent: 'center' }}>
           <FacebookLogin
               appId={this.getFbKey()}
               fields="name,email,picture.type(large)"
               callback={(rsp) => this.handleFBLoginCallback(rsp)}
               isMobile={false}
               render={renderProps => (
                   <FacebookLoginButton
                       text={"Continue with Facebook"}
                       onClick={() => {
                           renderProps.onClick()
                       }}
                       style={{}}
                   />)}
           />
       </div>}
        
       {this.props.showAppleLogin && <div id="social_media_lgin_view" style={{ display: 'flex', justifyContent: 'center', marginTop: "0.2%" }}>
            <AppleLogin 
                clientId={process.env.appleClientId} 
                redirectURI={this.props.appDomain == "witheta" ? "https://www.witheta.com/signup": "https://www.movement.fm/signup"}
                responseType="code id_token"
                responseMode="fragment"
                state={this.getStateString("apple")}
                render={renderProps => (
                    <AppleLoginButton
                        text={"Continue with Apple"}
                        onClick={() => {
                            renderProps.onClick()
                        }}
                        style={{}}
                />)}

            />
        </div>}

      { this.props.includeManual && <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
           <Button variant="light" 
               onMouseOver={(event: any) => event.target.style.textDecoration='underline'}
               onMouseLeave={(event: any) => event.target.style.textDecoration='none'} 
               onClick={this.handleContinueWithoutSocialLogin.bind(this)}>
               Continue as guest
           </Button>
       </div>}
   </React.Fragment>
    }
}