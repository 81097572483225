import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import {MdErrorOutline} from 'react-icons/md'
import { FaCheckCircle } from 'react-icons/fa'

export default class SystemModal extends React.Component {

	// static defaultProps = {
	// 	loading: false, 
	// 	error: false, 
	// 	message: undefined, 
	// 	onHide: null
	// }

	getView() { 
		if (this.props.loading) { 
			return (
				<div>
					<div style={{justifyContent: 'center', height:100, display: 'flex', alignItems: 'center', justifyContent:'center', alignSelf:'center'}}>
				      <Spinner animation="border" />
				    </div>
				</div>
		    )
		} else if (this.props.error) { 
			return (
				<Modal.Body>
					<div style={{justifyContent: 'center', height:100, display: 'flex', alignItems: 'center', justifyContent:'center', alignSelf:'center'}}>
			      		<MdErrorOutline style={{fontSize:50}}/>
			    	</div>
			    	<p style={{alignSelf:'center'}}>{this.props.message}</p>
			    	<Button variant="outline-dark" onClick={()=>this.handleOnClick(false)} block>
		          		Ok
		         	</Button>
		    	</Modal.Body>
			)
		} else if (this.props.error === false) {
			return (
				<Modal.Body>
					<div style={{justifyContent: 'center', height:100, display: 'flex', alignItems: 'center', justifyContent:'center', alignSelf:'center'}}>
			      		<FaCheckCircle style={{fontSize:50}}/>
			    	</div>
			    	<p style={{alignSelf:'center'}}>{this.props.message}</p>
			    <Button variant="outline-dark" onClick={()=>this.handleOnClick(true)} block>
		          	Ok
		         </Button>
		    	</Modal.Body>
			)
		} else { 
			return null
		}
	}

	handleExit(sucess) { 
		if (this.props.onHide != null || this.props.onHide != undefined) { 
			this.props.onHide(sucess)
		}
	}

	handleOnClick(success) {
		if (this.props.onClick === undefined || this.props.onClick === null) { 
			this.handleExit(success)
		} else { 
			this.props.onClick();
		}
	}

	render() { 
		return (<Modal
			size="sm"
			show={this.props.show} onHide={()=>this.handleExit()}>
			{this.getView()}
       	 </Modal>)
	}
}